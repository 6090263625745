<script>
export default {
  name: "GdprBanner",
  emits: ["showBanner"],
  data() {
    return {
      showBanner: false,
    }
  },
  methods: {
    getCookie() {
      return localStorage.getItem("gdpr_consent")
    },
    setCookie(consent) {
      localStorage.setItem("gdpr_consent", consent)
      this.showBanner = false
      this.$emit("showBanner", false)
      window.location.reload()
    },
    initBannerAndTracking() {
      const urlParams = new URLSearchParams(window.location.search)
      if (!urlParams.has("gdpr")) {
        if (this.getCookie() === null) {
          this.showBanner = true
          this.$emit("showBanner", true)
        }
      } else {
        if (this.getCookie() === null) {
          this.setCookie(urlParams.get("gdpr"))
        }
      }
    },
  },
  mounted() {
    this.initBannerAndTracking()
  },
}
</script>

<template>
  <aside v-if="showBanner"
         class="fixed w-full h-full top-0 left-0 select-none backdrop-blur bg-f-bg/20 hj:bg-transparent z-70">
    <div class="fixed absolute-center rounded-xl w-11/12 md:w-8/12 max-w-[600px] pt-10 p-8 bg-f-white">
      <h2
        class="font-graphik-bold text-xl md:text-3xl text-f-bg mb-2">
        We use cookies
      </h2>
      <p class="mb-4">
        To improve our website and your browsing experience, we use cookies to tailor our site and ads to what you are
        looking for. Visit
        <a
          :href="$page.props.brand_settings.privacy_policy"
          class="text-f-contrast font-graphik-medium underline cursor-pointer"
          target="_blank"
          >Cookie Policy</a
        >
        to learn more about our cookies.
      </p>
      <div class="flex items-center justify-start gap-4">
        <button
          class="w-full md:w-auto rounded-xl bg-f-contrast py-3 px-6 text-f-tertiary hj:text-f-white hover:text-f-white cursor-pointer hover:bg-f-tertiary hover:hj:bg-f-secondary hover:hj:bg-f-primary"
          @click="setCookie('off')">
          No I decline
        </button>
        <button
          class="w-full md:w-auto rounded-xl bg-f-contrast py-3 px-6 text-f-tertiary hj:text-f-white hover:text-f-white cursor-pointer hover:bg-f-tertiary hover:hj:bg-f-secondary hover:hj:bg-f-primary"
          @click="setCookie('on')">
          Yes I accept
        </button>
      </div>
    </div>
  </aside>
</template>

<style scoped></style>
